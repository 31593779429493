var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { buildExpireTimestamp, consoleDebug, hasTimestampExpired } from './utils';
var StorageAdapter = /** @class */ (function () {
    function StorageAdapter(options) {
        this.options = options;
    }
    StorageAdapter.prototype.setItem = function (key, item) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b, _c, stringify, _d, debugMode, data, content;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = this.options, _c = _b.stringify, stringify = _c === void 0 ? true : _c, _d = _b.debugMode, debugMode = _d === void 0 ? false : _d;
                        data = this.options.expireIn
                            ? Object.assign({}, item, {
                                __mps__: {
                                    expireInTimestamp: buildExpireTimestamp(this.options.expireIn),
                                },
                            })
                            : item;
                        content = stringify ? JSON.stringify(data) : data;
                        consoleDebug(debugMode, key + " - setItem:", content);
                        return [4 /*yield*/, ((_a = this.options.storage) === null || _a === void 0 ? void 0 : _a.setItem(key, content))];
                    case 1:
                        _e.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StorageAdapter.prototype.getItem = function (key) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c, _d, removeOnExpiration, _e, debugMode, storageData, parsedData, hasExpired;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _c = this.options, _d = _c.removeOnExpiration, removeOnExpiration = _d === void 0 ? true : _d, _e = _c.debugMode, debugMode = _e === void 0 ? false : _e;
                        return [4 /*yield*/, ((_a = this.options.storage) === null || _a === void 0 ? void 0 : _a.getItem(key))];
                    case 1:
                        storageData = _f.sent();
                        try {
                            parsedData = JSON.parse(storageData) || {};
                        }
                        catch (error) {
                            parsedData = storageData || {};
                        }
                        hasExpired = hasTimestampExpired((_b = parsedData.__mps__) === null || _b === void 0 ? void 0 : _b.expireInTimestamp);
                        consoleDebug(debugMode, key + " - hasExpired", hasExpired);
                        if (!(hasExpired && removeOnExpiration)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.removeItem(key)];
                    case 2:
                        _f.sent();
                        _f.label = 3;
                    case 3:
                        parsedData = hasExpired ? {} : parsedData;
                        consoleDebug(debugMode, key + " - (getItem):", parsedData);
                        return [2 /*return*/, parsedData];
                }
            });
        });
    };
    StorageAdapter.prototype.removeItem = function (key) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _b, debugMode;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = this.options.debugMode, debugMode = _b === void 0 ? false : _b;
                        consoleDebug(debugMode, key + " - (removeItem): storage was removed");
                        return [4 /*yield*/, ((_a = this.options.storage) === null || _a === void 0 ? void 0 : _a.removeItem(key))];
                    case 1:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return StorageAdapter;
}());
export { StorageAdapter };
